import { autoSuggest } from "@/helpers/api/service/search";
import debounce from "lodash/debounce";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import AutoComplete from "../AutoComplete";
import FaMicrophone from "../SVG/Fontawesome/FaMicrophone";
import FaSearch from "../SVG/Fontawesome/FaSearch";
import Camera from "./Camera";
import LinkWrapper from "../LinkWrapper";
import { useDispatch } from "react-redux";
import InputPlaceholder from "./InputPlaceholder";
import VoiceSearchIcon from "./VoiceSearchIcon";
import { updateOffCanvasView, offCanvasInitialState } from "@/store/slices/offCanvas";
import { getMatches } from "@/helpers/api/service/ask";
import { getUUID } from "@/utils/auth";

const Search = () => {
  const [mobile, setMobile] = useState<boolean>();
  const [inFocus, setInFocus] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<any[]>();
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const dispatch = useDispatch();

  const debouncedResults = debounce(async () => {
    if (!inputRef.current?.value) {
      return;
    }
    const { data: { suggestions } } = await autoSuggest(inputRef.current.value);
    setSuggestions(suggestions);
  }, 300);

  const getIntermediateResults = () => {
    if (!inputRef.current?.value) {
      return;
    }
    debouncedResults();
  }

  const openCameraBS = (index: number) => {
    dispatch(updateOffCanvasView({
      name: "CAMERA",
      data: {
        CAMERA: {
          ...offCanvasInitialState.data.CAMERA,
          cameraBSTabIndex: index,
        }
      }
    }))
  }


  const search = async (event: any) => {
    if (!inputRef.current?.value) return;
    if (!(event.keyCode === 13 || event.type === "click")) return;
    const { data } = await getMatches(getUUID(), undefined, undefined, inputRef.current.value);
    if (!data) return;
    dispatch(updateOffCanvasView({
      name: "CAMERA",
      data: {
        CAMERA: {
          ...offCanvasInitialState.data.CAMERA,
          text: inputRef.current.value,
          questionId: data.question_id,
          matchedQuestions: data.matched_questions ?? [],
        },
      }
    }));
  }

  const onFocus = () => {
    setTimeout(() => {
      setInFocus(true);
    }, 300);
  }

  const onBlur = () => {
    setTimeout(() => {
      setInFocus(false);
    }, 300);
  }

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.onfocus = onFocus;
    inputRef.current.onblur = onBlur;
  }, [inputRef.current])

  useEffect(() => {
    if (!router.query.q || typeof router.query.q !== "string") {
      return;
    }
    if (inputRef.current) {
      inputRef.current.value = router.query.q;
      getIntermediateResults()
    }
  }, [router.query.q, inputRef.current]);

  useEffect(() => {
    setMobile(isMobile);
  }, []);

  const expandedLabel = <div className={`relative ${inFocus ? "w-96" : "w-80"} flex items-center`}>
    <span className="absolute z-[99] -top-3 left-5">
      <button onClick={search} id="hd-search" name='hd-search'>
        <FaSearch className="w-5 h-5" />
      </button>
    </span>
    <InputPlaceholder
      inputRef={inputRef}
      className=" absolute border-border-mute-active border-2 focus:border-border-brand-active right-0 w-80 sm:focus:w-96 h-12 text-base transition-all delay-300 rounded-full pl-12 pr-16 bg-secondary"
      onChange={getIntermediateResults}
      onKeyDown={search}
      onFocus={onFocus}
    />
    <span className="absolute right-4 fill-body flex items-center space-x-4">
      <VoiceSearchIcon />
      <span id="hd-camera" className="inline">
        <Camera color="fill-inherit" />
      </span>
    </span>
  </div>;


  const mobilelabel = <div className="relative flex items-center gap-6 pr-2 fill-body">
    <button id="hd-search" name="search-btn" onClick={() => openCameraBS(2)}>
      <FaSearch />
    </button>
    <button id="hd-mic" name="mic-btn" onClick={() => openCameraBS(0)}>
      <FaMicrophone />
    </button>
  </div>;

  return (
    <span className="text-xl relative inline-flex cursor-pointer items-center justify-center p-0 rounded-full flex-col">
      {mobile === false && expandedLabel}
      {/* {mobile && mobilelabel} */}
      {inFocus &&
        <div className="max-w-sm w-96 mx-auto bg-secondary-light overflow-auto md:max-w-sm absolute top-7 right-0 text-sm tracking-wider rounded-lg z-20">
          <AutoComplete inputRef={inputRef} suggestions={suggestions} />
        </div>
      }
    </span>
  );
}

export default Search;